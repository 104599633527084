import { AuthContext } from "@providers/AuthProvider";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Localization from "@localization/Index";
import { DEFAULT_LANG } from "@utils/Constants";

type Props = {
    children: JSX.Element
}

const Protected = (props:Props):JSX.Element => {

    const auth = React.useContext(AuthContext)
    
    const { children } = props
    const location = useLocation()
    if(auth.userData===null) {
        return <></>
    }
    if(!auth.userData) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    Localization.setLanguage(auth.userData?.defaultLang ?? DEFAULT_LANG)

    //TODO: define layout with navbar in alto
    return (
        <div className="h-100 w-100">
            {children}
        </div>
    )
}

export default Protected
import { getCart } from "@api/Cart";
import { Order } from "@csapi/provisioning/api";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthProvider";
import { generateEmptyOrder } from "@utils/OrderUtils";

interface CartContextType {
    cart: Order,
    setCart: React.Dispatch<React.SetStateAction<Order>>
    resetCart: ()=>unknown
    loadCart: ()=>Promise<Order>
}

const CartContext = createContext<CartContextType>(null!)

function CartProvider({ children }: { children: React.ReactNode }) {
    const emptyCart = generateEmptyOrder()
    const [cart, setCart] = useState<Order>(emptyCart)
    const auth = useContext(AuthContext)

    useEffect(()=>{
        if(auth.userData?.id) {
            resetCart()
            loadCart()
        }
    }, [auth.userData?.id])

    const loadCart = async()=>{
        if(!auth.userData?.id) return emptyCart
        const response = await getCart()
        setCart(response ?? emptyCart)
        return response ?? emptyCart
    }

    const resetCart = ()=>{
        return setCart(emptyCart)
    }


    const value = { cart, setCart, resetCart, loadCart }
    return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export { CartProvider, CartContext } 
import { EntityListResponseObject, Item, Order } from "@csapi/provisioning/api"
import axios, { AxiosResponse } from "axios"
import { checkResponseStatus } from "./Utils"
import { CatalogFilters } from "@utils/FiltersUtils"
import { CSApiParentItemFilterV1ImagesOptionEnum, CSApiParentItemFilterV2, EntityListWithAdditionalInfoListResponseParentSkuAndCSApiShopItemsCsApiShopItemAdditionalInfo, EntityListWithAdditionalInfoListResponseShopItemCsApiShopItemAdditionalInfo } from '../../../src/csapi/shop'
import { NotFoundItemInArray } from '../../../src/interfaces/Items'
import { distinct } from "@utils/ArrayUtils"


const getMetadata = async (field:string):Promise<EntityListResponseObject> =>{
    try {
        const response:AxiosResponse<EntityListResponseObject,any> = await axios.get(`api/items/metadata/${field}`)
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}

const getCatalog = async (filters:CatalogFilters, pageIndex: number, pageSize: number, sort: string, controller: AbortController):Promise<EntityListWithAdditionalInfoListResponseParentSkuAndCSApiShopItemsCsApiShopItemAdditionalInfo> =>{
    try {
        console.log({filters})
        // transform filters into ParentItemFilter
        const body:CSApiParentItemFilterV2 = {}
        const custom_fields:{[key: string]: object} = {}
        Object.keys(filters).forEach(filter => {
            const value = filters[filter]
            if(filter==="brand") {
                return body.brands = {
                    op: "IN",
                    values: value as unknown as Set<string>// new Set(value as string[])
                }
            }
            if(filter==="cat_ids") {
                return body.cat_ids = {
                    op: "IN",
                    values: (value as string[]).map($oid=>({$oid}))
                }
            }
            if(filter==="images_option") {
                return body.images_option = value as CSApiParentItemFilterV1ImagesOptionEnum
            }
            if(["fta","carryover"].includes(filter)) {
                console.log(filter, value)
                return custom_fields[filter] = value as object
            }
            return custom_fields[filter] = {op: "IN", values: value}
        })
        body.custom_fields = custom_fields
        const response:AxiosResponse<EntityListWithAdditionalInfoListResponseParentSkuAndCSApiShopItemsCsApiShopItemAdditionalInfo,any> = await axios.post(`api/items/catalog`, {
            body, pageIndex, pageSize, sort
        }, {
            signal: controller.signal
        })
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}

const getItemsByIdList = async(idList: string[])=>{
    try {
        const response:AxiosResponse<Array<Item | NotFoundItemInArray>, any> =  await axios.post(`api/items/getItemsByIdList`, idList)
        return response.data

    } catch (error: any) {
        checkResponseStatus(error)
        throw error
    }
}

const searchByCode = async(searchTerm: string, types: string[], searchType:"EXACT" | "PARTIAL_CASE_SENSITIVE" | "PARTIAL_CASE_INSENSITIVE"="EXACT", controller?: AbortController) =>{
    try{
        const response:AxiosResponse<{[key:string]:EntityListWithAdditionalInfoListResponseShopItemCsApiShopItemAdditionalInfo},any> = await axios.get(`api/items/search-by-codes/`, {
            params: {searchTerm, types, searchType},
            signal: controller?.signal
        })
        return response.data
    } catch(error:any) {
        if (error.name !== 'CanceledError') {
            checkResponseStatus(error)
            throw error
        }
    }
}

const searchByTitle = async(searchTerm: string, language: string, pageIndex?: number, pageSize?: number, controller?: AbortController) =>{
    try{
        const body:CSApiParentItemFilterV2 = {}
        const custom_fields:{[key: string]: object} = {
            [`title_${language}`]: searchTerm as unknown as object
        }

        body.custom_fields = custom_fields
        const response:AxiosResponse<EntityListWithAdditionalInfoListResponseParentSkuAndCSApiShopItemsCsApiShopItemAdditionalInfo,any> = await axios.post(`api/items/catalog`, {
            body,  pageIndex: pageIndex ?? 0, pageSize: pageSize ?? 250, sort: "last_info_update[DESC]"
        }, {
            signal: controller?.signal
        })
        return response.data
    } catch(error:any) {
        if (error.name !== 'CanceledError') {
            checkResponseStatus(error)
            throw error
        }
    }
}

const getItemsByParentSku = async (parentSKU:string[]):Promise<EntityListWithAdditionalInfoListResponseShopItemCsApiShopItemAdditionalInfo> =>{
    try {
        const params ={
            parentSKU:parentSKU
        }
        const response:AxiosResponse<EntityListWithAdditionalInfoListResponseShopItemCsApiShopItemAdditionalInfo,any> = await axios.get(`api/items/parentSKU`,{params})
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}

const getOrderItemsInfo = async (order : Order): Promise<EntityListWithAdditionalInfoListResponseShopItemCsApiShopItemAdditionalInfo> =>{
    
    const skus: string[] = extractSkuParents(order).filter(distinct)
    if(skus.length === 0) return {content: []}

    try {
        return getItemsByParentSku(skus)

    }catch (error: any){
        checkResponseStatus(error)
        throw error  
    }                  
}

function extractSkuParents(order: Order): string[] {
    return order.items?.reduce((acc: string[], item) => {
        if (item.attributes && typeof item.attributes['sku_parent'] === 'string') {
            acc.push(item.attributes['sku_parent']);
        }
        return acc;
    }, []) || [];
}

export {
    getMetadata,
    getCatalog,
    getItemsByIdList,
    getOrderItemsInfo,
    searchByCode,
    searchByTitle,
    getItemsByParentSku
}
import CloudStoreLogo from '@svgs/logo.svg'

const DomainNotConfigured = ()=>{
    return <div className="d-flex justify-content-center align-items-center h-100">
    <div className="w-50 h-100 d-flex justify-content-start align-items-center bg-muted">
            <div className="d-flex flex-column" style={{height: '400px'}}>
                <img className='cy-logo' src={CloudStoreLogo} width="200" alt="logo" />
                <div style={{fontSize: 96, lineHeight: '96px', color: '#1c2e63'}}>
                    <span style={{color: '#ff635c'}}>Be</span>Commerce
                </div>
                <div className="h6 text-muted text-center mt-field">
                    <span>Il dominio <span style={{color: '#ff635c'}}>{window.location.hostname}</span> non è ancora attivo per questa applicazione.</span>
                    </div>
            </div>
        </div>
    </div>
}

export default DomainNotConfigured
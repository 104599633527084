import parseISO from "date-fns/parseISO"

/**
 * set an item into the local storage, or remove it if the item is null or undefined
 * @param key the local storage key
 * @param item the item to be stored
 */
const setLocalStorageItem = (key: string, item?: unknown)=>{
    if(!key) return
    if(item===null || item===undefined) {
        return localStorage.removeItem(key)
    }

    if(typeof item === "string") {
        return localStorage.setItem(key, item)
    }

    localStorage.setItem(key, JSON.stringify(item, function(this, key, value) {
        if(this[key] instanceof Date) {
            return `__DATE__${value}`
        }
        return value
    }))
}

/**
 * get an item from the localstorage
 * @param key the local storage key
 * @param defaultValue optional, the value to be returned if no item is fetch from the local storage 
 * @returns the item stored
 */
const getLocalStorageItem = (key:string, defaultValue?: unknown) =>{
    if(!key) return null
    
    const savedItem = localStorage.getItem(key)
    try{
        const parsedItem = (savedItem)?JSON.parse(savedItem, function(this, key, value) {
            if(typeof this[key]==='string' && this[key].includes("__DATE__")) {
                return parseISO(this[key].replace("__DATE__", ""))
            }

            return value
        }):null
        return parsedItem ?? defaultValue
    } catch(e) {
        return savedItem ?? defaultValue
    }
}

/**
 * Reset the current local storage for the current session
 */
const resetCurrentStorageSession = ()=>{
    for(const i in localStorage) {
        if (localStorage.hasOwnProperty(i) && (i.match(/filters\./i) || i.match(/user\./i) || i.match(/sort\./i))) {
            setLocalStorageItem(i, null)
        }
    }
    return
}
export {setLocalStorageItem, getLocalStorageItem, resetCurrentStorageSession}
import { EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo } from "@csapi/provisioning/api"
import axios, { AxiosResponse } from "axios"
import { checkResponseStatus } from "./Utils"
import { endOfDay, startOfDay, subMonths } from "date-fns"
import format from "date-fns/format"
import { EntitySingleResponseOrder, Order } from "@csapi/shop"

const getCart = async ():Promise<Order|undefined> =>{
    try {
        const params = {
            pageIndex: 0, 
            pageSize: 1,
            statuses: "PENDING",
            sort: "date[ASC]",
            dateFrom: startOfDay(subMonths(new Date(), 120)).toISOString(),
            dateTo: endOfDay(new Date()).toISOString(),
        }
        const response:AxiosResponse<EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo,any> = await axios.get('api/orders', {
            params
        })
        const orderList = response.data.content

        if(!orderList || orderList.length === 0) {
            return undefined
        } 

        return orderList[0]
    } catch(error: any) {
        checkResponseStatus(error)
        throw error
    }
}

export { getCart }
